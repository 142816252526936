import React from 'react'
//import '../style/home.css';
//import axiosInstance from '../axios-instance';
import Nav from '../componants/nav';
import Search from '../componants/search';
import Footer from '../componants/footer';
import LoginButton from '../componants/loginButton'

//Image
import logo from '../images/logo.png'
//import logo_footer from '../images/logo_landscape_white.png'

const nodeInfo = () => {
    const d = JSON.parse(localStorage.getItem(window.location.pathname.split('detail/node/').pop()));
    return (
        <div className='container'>
            <p>Title: {d.title}</p>
            <p>Hash: {d.hash_id}</p>
            <p>Owner: {d.owner_org}</p>
            <p>Notes: {d.notes}</p>
        </div>
    );
  };

  export default nodeInfo;