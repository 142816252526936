import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
//import Tree_react from 'react-tree-graph';
import * as d3 from 'd3';

const Lineage = (props) => {
    var treeData = props.lineage_data;
    // var treeData = {
    //     "hash_id": "hash1",
    //     "title": "Top Level",
    //     "notes": "descriptions",
    //     "owner_org": "organization1",
    //     "children": [
    //         {
    //             "hash_id": "hash2",
    //             "title": "Level 2: A",
    //             "notes": "descriptions",
    //             "owner_org": "organization2",
    //             "children": [
    //                 {
    //                     "hash_id": "hash31",
    //                     "title": "Level 3: A1",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash32",
    //                     "title": "Level 3: A2",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash33",
    //                     "title": "Level 3: A3",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash34",
    //                     "title": "Level 3: A4",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash35",
    //                     "title": "Level 3: A5",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash36",
    //                     "title": "Level 3: A6",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash37",
    //                     "title": "Level 3: A7",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash38",
    //                     "title": "Level 3: A8",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash39",
    //                     "title": "Level 3: A9",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash40",
    //                     "title": "Level 3: A10",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 }
    //             ]
    //         },
    //         {
    //             "hash_id": "hash2",
    //             "title": "Level 2: A",
    //             "notes": "descriptions",
    //             "owner_org": "organization2",
    //             "children": [
    //                 {
    //                     "hash_id": "hash31",
    //                     "title": "Level 3: A1",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash32",
    //                     "title": "Level 3: A2",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash33",
    //                     "title": "Level 3: A3",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 }
    //             ]
    //         },
    //         {
    //             "hash_id": "hash2",
    //             "title": "Level 2: A",
    //             "notes": "descriptions",
    //             "owner_org": "organization2",
    //             "children": [
    //                 {
    //                     "hash_id": "hash31",
    //                     "title": "Level 3: A1",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash32",
    //                     "title": "Level 3: A2",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 },
    //                 {
    //                     "hash_id": "hash33",
    //                     "title": "Level 3: A3",
    //                     "notes": "descriptions",
    //                     "owner_org": "organization3",
    //                     "children": []
    //                 }
    //             ]
    //         }
    //     ]
    // };

    // Set the dimensions and margins of the diagram
    var margin = { top: -60, right: 30, bottom: 30, left: 30 },
        width = 1200 - margin.left - margin.right,
        height = 750 - margin.top - margin.bottom;

    useEffect(() => {
        d3.select("#tree-container").selectAll("*").remove();

        var svg = d3.select("#tree-container").append("svg")
            .attr("width", width + margin.right + margin.left)
            .attr("height", height + margin.top + margin.bottom)
            .call(d3.zoom().on("zoom", function (event) {
                svg.attr("transform", event.transform);
            }))
            .call(d3.zoom().transform, d3.zoomIdentity.translate(0, 350).scale(0.68))
            .append("g")
            .attr("transform", "translate(0,350) scale(0.68)");

        var i = 0;
        var root;

        // declares a tree layout and assigns the size
        var treemap = d3.tree().size([height, width]).nodeSize([75]);

        // Assigns parent, children, height, depth
        root = d3.hierarchy(treeData, function (d) { return d.children; });

        //if api error
        if (treeData == '') {
            console.log(props.lineage_data);
            document.getElementById('nodata').style.display = 'block';
            //update(root);
        } else {

            update(root);
        }

        // Collapse the node and all it's children
        function collapse(d) {
            if (d.children) {
                d._children = d.children
                d._children.forEach(collapse)
                d.children = null
            }
        }

        // mouse event vars
        var mousedownNode = null;
        let mouseupNode = null;

        function resetMouseVars() {
            mousedownNode = null;
            mouseupNode = null;
        }

        function update(source) {

            var treeData = treemap(root);

            // Compute the new tree layout.
            var nodes = treeData.descendants(),
                links = treeData.descendants().slice(1);

            // Normalize for fixed-depth.
            nodes.forEach(function (d) { d.y = d.depth * 200 });

            // ****************** Nodes section ***************************

            //Tooltips
            d3.select('ct-visualization')
                .append('div')
                .attr('id', 'tooltip')
                .attr('style', 'position: absolute; opacity: 0;');

            // Update the nodes...
            var node = svg.selectAll('g.node')
                .data(nodes, function (d) { return d; });

            // Enter any new modes at the parent's previous position.
            var nodeEnter = node.enter().append('g')
                .attr('class', 'node')
                .on("mouseover", function (d, i) {      //Hover show tooltip
                    d3.select('#tooltip')
                        .style('opacity', 1).html(`${i.data.title}<br>${i.data.hash_id}<br>${i.data.owner_org}<br>${i.data.notes}`);
                    // d3.select(this)
                    //     .attr("filter", brightness(0.90));
                })
                .on('mouseout', function () {
                    d3.select('#tooltip').style('opacity', 0)
                })
                .on('mousemove', function (d) {
                    d3.select('#tooltip').style('left', (d.pageX + 20) + 'px').style('top', (d.pageY + 10) + 'px')
                })
                .on("mousedown", function (d, i) {      //Click new window
                    clickNode(i.data);
                });
            var rectHeight = 60, rectWidth = 120;

            nodeEnter.append('rect')
                .attr('class', 'node')
                .attr("width", rectWidth)
                .attr("height", rectHeight)
                .attr("x", function (d) {
                    return width - d.y;
                })
                .attr("y", function (d) {
                    return d.x;
                })
                .attr("rx", "5");

            //Arrow
            svg.append("svg:defs").append("svg:marker")
                .attr("id", "triangle")
                .attr("refX", 13) //24
                .attr("refY", 6)
                .attr("markerWidth", 30)
                .attr("markerHeight", 30)
                .attr("orient", "auto")
                .append("path")
                .attr("d", "M2,2 L10,6 L2,10 L3,6 L2,2");
            // .attr("d", "M0,-5L10,0L0,5")
            // .attr("d", d3.line())
            // .style("fill", "#909090");

            // Add labels for the nodes
            nodeEnter.append('text')
                .attr("dy", function (d) {
                    return d.x + 22;
                })
                .attr("x", function (d) {
                    return width - d.y + 10;
                })
                .attr("text-anchor", function (d) {
                    return "start";
                })
                .text(function (d) {
                    if (d.data.title.length > 15) {
                        return d.data.title.substring(0, 13) + "...";
                    } else {
                        return d.data.title;
                    }
                })
                .append("tspan")
                .attr("dy", "2em")
                .attr("x", function (d) {
                    return width - d.y + 10;
                })
                .text(function (d) {
                    if (d.data.owner_org.length > 15) {
                        return d.data.owner_org.substring(0, 15) + "...";
                    } else {
                        return d.data.owner_org;
                    }
                });

            // ****************** links section ***************************

            // Update the links...
            var link = svg.selectAll('path.link')
                .data(links, function (d) { return d.id; });

            // Enter any new links at the parent's previous position.
            var linkEnter = link.enter().insert('path', "g")
                .attr("class", "link")
                .attr("marker-end", "url(#triangle)")
                .attr('d', function (d) {
                    //console.log(d)
                    return linkSpec(d, d.parent)
                });

            function linkSpec(s, d) {
                var p0 = {
                    x: s.x + rectHeight / 2,
                    y: (width - s.y + rectWidth)
                }, p3 = {
                    x: d.x + rectHeight / 2,
                    y: width - d.y + 10 // -12, so the end arrows are just before the rect node
                }, m = (p0.y + p3.y) / 2 - 30, p = [p0, {
                    x: p0.x,
                    y: m + 35
                }, {
                        x: p3.x,
                        y: m - 10
                    }, p3];
                p = p.map(function (d) {
                    return [d.y, d.x];
                });

                // var path = `M ${ width - s.y+rectWidth} ${s.x + rectHeight / 2}
                //         ${width - d.y +5} ${d.x + rectHeight / 2 } 
                //         C ${p[1]} ${p[2]} ${p[3]} `
                var path = `M ${p[0]} C ${p[1]} ${p[2]} ${p[3]} `

                //console.log('Path : '+path)
                return path
            }

            // Toggle children on click.
            function click(d) {
                if (d.children) {
                    d._children = d.children;
                    d.children = null;
                } else {
                    d.children = d._children;
                    d._children = null;
                }
                update(d);
            }
        }

    }, []);

    function clickNode(d) {
        console.log('d', d);
        localStorage.setItem(`${d.hash_id}`, JSON.stringify(d));
        //window.open(`/detail/node/${d.hash_id}`, "_blank");
        window.open(`/detail/${d.hash_id}`, "_blank");
    }

    return (
        <div className='lineage-container'>
            <div className='lineage-content'>
                <div id='nodata'>
                    <div class="ant-empty ant-empty-normal"><div class="ant-empty-image"><svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg></div><div class="ant-empty-description">No Data</div></div>
                </div>
                <ct-visualization id="tree-container"></ct-visualization>
            </div>
        </div>

    )
}

export default Lineage;