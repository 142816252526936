import React from 'react'
import '../style/home.css';

//import axiosInstance from '../axios-instance';

import Nav from '../componants/nav';
import Search from '../componants/search';
import Footer from '../componants/footer';
import LoginButton from '../componants/loginButton'

//Image
import logo from '../images/logo.png'
//import logo_footer from '../images/logo_landscape_white.png'

const Home = () => {
    return (
        <div className='container'>
            <img src={logo} className="home-logo" alt="logo" />
            {/* Search */}
            <Search />
        </div>
    );
};

export default Home;
