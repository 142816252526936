import '../style/footer.css';
import React from 'react'
import logo_footer from '../images/logo_landscape_white.png'

const Footer = () => {

    
    return(
        <div className="footer-container">
            {/* Footer */}
            <img src={logo_footer} className="footer-logo" alt="logo footer" />
            <div className="footer-address">
                    <p>บริษัท ดิจิทัลสโตร์เมซ จำกัด (สำนักงานใหญ่)</p>
                    <p>131 อาคารกลุ่มนวัตกรรม1 ห้องเลขที่ INC1-217 ชั้นที่ 2 
                        หมู่ที่ 9 พหลโยธิน ตำบลคลองหนึ่ง อำเภอคลองหลวง 
                        จังหวัดปทุมธานี 12120</p>
                    <p>Telephone: 02-147-0789 | Email: contact@storemesh.com</p>
            </div>
        </div>
    );
};

export default Footer;
