import '../style/header.css';
import React, { useState, useEffect, useRef } from 'react'
//import grid_icon from '../images/grid-icon.png';
import grid_icon from '../images/icon_menu.png';
import g from '../images/icon_nav/g.png';
import d from '../images/icon_nav/d.png';
import j from '../images/icon_nav/j.png';
import e from '../images/icon_nav/e.png';
import b from '../images/icon_nav/b.png';
import c from '../images/icon_nav/c.png';
import v from '../images/icon_nav/v.png';
import h from '../images/icon_nav/h.png';

let useClickOutside = (handler) => {
    let domNode = useRef();
  
    useEffect(() => {
      let maybeHandler = (event) => {
        if (!domNode.current.contains(event.target)) {
            handler();
        }
      };
  
      document.addEventListener("mousedown", maybeHandler);
  
      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });
  
    return domNode;
  };

const Nav = () => {

    const [show,setShow] = useState(false);

    let domNode = useClickOutside(() => {
        setShow(false);
      });

    return(
        <div ref={domNode} className='nav-container'>
            <button className="popup-btn" onClick={()=>setShow(!show)}><img src={grid_icon} alt="icon apps button" /></button>
                        {  show? <div className="popup-box">
                          {/* `${process.env.REACT_APP_DISCOVERY_URL}` */}
                                    <a href='http://governance.data.storemesh.com/' target="_blank" className="popup-item" rel="noreferrer" ><img src={g} alt="icon app button" /><br/>Governance</a>
                                    <a href='http://data.storemesh.com/' target="_blank" className="popup-item" rel="noreferrer" ><img src={d} alt="icon app button" /><br/>Discovery</a>
                                    <a href='http://jupyter.data.storemesh.com/' target="_blank" className="popup-item" rel="noreferrer" ><img src={j} alt="icon app button" /><br/>Jupyter Hub</a>
                                    <a href='http://etl.data.storemesh.com/' target="_blank" className="popup-item" rel="noreferrer" ><img src={e} alt="icon app button" /><br/>ETL</a>
                                    <a href='http://bi.data.storemesh.com/' target="_blank" className="popup-item" rel="noreferrer" ><img src={b} alt="icon app button" /><br/>BI</a>
                                </div>:null
                        }
            
        </div>
    )
}

export default Nav;