import React from 'react'
import { Table } from 'antd';

const Metadata = (props) => {
    const topic_dic = [{
        eng: 'data_type',
        th: 'ประเภทข้อมูล'
    },{
        eng: 'title',
        th: 'ชื่อชุดข้อมูล'
    },{
        eng: 'owner_org',
        th: 'องค์กร'
    },{
        eng: 'maintainer',
        th: 'ชื่อผู้ติดต่อ'
    },{
        eng: 'maintainer_email',
        th: 'อีเมลผู้ติดต่อ'
    },{
        eng: 'tag_string',
        th: 'คำสำคัญ'
    },{
        eng: 'notes',
        th: 'รายละเอียด'
    },{
        eng: 'objective',
        th: 'วัตถุประสงค์'
    },{
        eng: 'update_frequency_unit',
        th: 'หน่วยความถี่ของการปรับปรุงข้อมูล'
    },{
        eng: 'update_frequency_interval',
        th: 'ค่าความถี่ของการปรับปรุงข้อมูล'
    },{
        eng: 'geo_coverage',
        th: 'ขอบเขตเชิงภูมิศาสตร์หรือเชิงพื้นที'
    },{
        eng: 'data_source',
        th: 'แหล่งที่มา'
    },{
        eng: 'data_format',
        th: 'รูปแบบการเก็บข้อมูล'
    },{
        eng: 'data_category',
        th: 'หมวดหมู่ข้อมูลตามธรรมาภิบาลข้อมูลภาครัฐ'
    },{
        eng: 'license_id',
        th: 'สัญญาอนุญาตให้ใช้ข้อมูล'
    },{
        eng: 'parents',
        th: 'Parents'
    },{
        eng: 'reference_url',
        th: 'Reference URL'
    },{
        eng: 'connector',
        th: 'Connector'
    },{
        eng: 'other',
        th: 'อื่น ๆ'
    },{
        eng: 'create_at',
        th: 'วันที่เริ่มต้นสร้าง'
    },{
        eng: 'update_at',
        th: 'วันที่ปรับปรุงข้อมูลล่าสุด'
    }];
    let rows_info = [];
    const columns_info = [
        {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic'
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            key: 'detail',
            render: text => {
                if (Array.isArray(text)) {
                    return text.map((data, index) => {
                        return <label key={data}>{index + 1} {data}<br></br></label>
                    })
                }
                else {
                    return text
                }
            }
        }
    ];

    try {
        Object.entries(props.meta_data.metadata).map((k, v) => rows_info.push({
            topic: k[0],
            detail: k[1]
        }))
        for (let i = 0; i < rows_info.length; i++){
            //Code แบบไม่ฟิกลำดับ เปลี่ยนเป็นคำไทยเฉพาะ topic eng ที่ตรงกันเท่านั้น
            for (let t = 0; t < topic_dic.length; t++){
                if (rows_info[i].topic == topic_dic[t].eng) {
                    rows_info[i].topic = topic_dic[t].th;
                }
            }
            // Code แบบฟิกลำดับ แต่ถ้าลำดับเรียงตรงกันจะใช้เช็คได้ว่า topic อันไหนไม่ตรงกับ dic และไม่ได้ถูกเปลี่ยนเป็นคำไทย
            // if(topic_dic[i].name == rows_info[i].topic){
            //     rows_info[i].topic = topic_dic[i].value;
            // }else{
            //     console.log('r:', i,'\ndic:',topic_dic[i].name , '\ntopic in database:',rows_info[i].topic);
            // }
            // console.log('r:', i);
        }
    } catch { }

    return (
        <div className='metadata-container'>
            {/* <Table columns={columns_info} dataSource={rows_info} pagination={{ pageSize: 9 }} rowKey='topic' /> */}
            <Table columns={columns_info} dataSource={rows_info} pagination={false}  rowKey='topic' />
        </div>
    )
}

export default Metadata;