import React from "react";
import { Route } from "react-router-dom";
import Header from "../componants/header"
import Home from "./home";
import Lists from "./lists";
import Details from "./details.js";
import nodeInfo from "./nodeInfo";
import MyDataset from "./mydataset";
import '../style/home.css';
import logo_footer from '../images/logo_landscape_white.png'

const Main = () => {

  return (
    <>
      <div className="main-wrapper">
        <div className="header-container">
          <Header />

        </div>
        <div className="content-container">
          
          <Route exact path="/" component={Home} />
          <Route exact path="/search/:search" component={Lists} />
          <Route exact path="/detail/:id" component={Details} />
          <Route exact path="/detail/node/:hash" component={nodeInfo} />
          <Route exact path="/user" component={MyDataset} />

        </div>

        <div className="footer-container">
          <img src={logo_footer} className="footer-logo" alt="logo footer" />
          <div className="footer-address">
            <p>บริษัท ดิจิทัลสโตร์เมซ จำกัด (สำนักงานใหญ่)</p>
            <p>131 อาคารกลุ่มนวัตกรรม1 ห้องเลขที่ INC1-217 ชั้นที่ 2
              หมู่ที่ 9 พหลโยธิน ตำบลคลองหนึ่ง อำเภอคลองหลวง
              จังหวัดปทุมธานี 12120</p>
            <p>Telephone: 02-147-0789 | Email: contact@storemesh.com</p>
          </div>

        </div>

      </div>
    </>

  );
};


export default Main;