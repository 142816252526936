function getCookie(name) {
    let dc = document.cookie;
    let prefix = name + "=";
    let begin = dc.indexOf("; " + prefix);
    if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end === -1) {
            end = dc.length;
        }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
}

function getUser(name){
    let dsm_jwt = getCookie(name);
    if (dsm_jwt !== null){
        let jwt_data = JSON.parse(atob(dsm_jwt.split('.')[1]));
        return jwt_data['username']
    }
    return ""
}

function delete_cookie(name) {
    if( getCookie( name )){
        document.cookie = `${name}= ; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
} 
export  {getCookie, getUser, delete_cookie}