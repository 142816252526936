import '../style/header.css';
import React from 'react'
import { useHistory,useLocation } from "react-router-dom";
import Nav from './nav';
import Search from './search';
import LoginButton from './loginButton'

//Image
import logo_header from '../images/logo_landscape.png'

const Header = () => {
    const location = useLocation();
   
    let history = useHistory();
    let HomePage = () => {
        history.push("/");
    }
    
    return(
        <div className='home-header-container'>
            {location.pathname === "/" ? null :
                <div className='header-left'>
               <img onClick={HomePage} src={logo_header} className="header-logo" alt="logo header" />
                {/* Search */}
                <Search />
                </div> }
         
            <div className='header-right'>
                <Nav />
                {/* <LoginButton /> */}
            </div>
        </div>
    )
}

export default Header;