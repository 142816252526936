import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd';
//import {render} from 'react-dom'

import '../style/detail.css';
import axiosInstance from '../axios-instance';
import { useParams } from "react-router-dom";

import Footer from '../componants/footer';
import Header from '../componants/header';
import Metadata from '../componants/metadata'
import Dataset from '../componants/dataset'
import Discussion from '../componants/discussion'
import Lineage from '../componants/lineage'

let Info = () => {
    let { TabPane } = Tabs;
    let params = useParams();
    let [data_info, setDataInfo] = useState([]);
    let [file, setFile] = useState([]);
    let [comment, setComment] = useState([]);
    let [dataLineage, setDataLineage] = useState([]);

    // json-server --watch db.json --port 8020
    // Get Data Info and Data Explorer
    let getInfo = (id) => {
        // axiosInstance.get(`v1/datasets/${id}`)
        axiosInstance.get(`/datasets/${id}/`)
            .then(response => {
                setDataInfo(response.data);
                // setLike(response.data.other.like);
                // setDislike(response.data.other.dislike);
                //setComment(response.data.discussion);
                setDataLineage(response.data.data_lineage);
                setFile(response.data.data_explorer);
                console.log(response.data.data_explorer);
            })
            .catch(e => {
                console.log(e);
            });
    }
    useEffect(() => {
        getInfo(window.location.pathname.split('detail/').pop());
    }, [params.id]);

    let dataset_id = data_info.id
    let title = ""
    try {
        title += data_info.metadata.title
    } catch { }

    // Like and Dislike 
    var [like, setLike] = useState();
    var [dislike, setDislike] = useState();
    var [statuslike, setStatuslike] = useState(false);
    var [statusdislike, setStatusdislike] = useState(false);

    function clicklike() {
        if (statusdislike === true) {
            setDislike(dislike - 1);
            setStatusdislike(false);
            document.getElementById('icon-dislike').style.fill = '#909090';
            document.getElementById('text-dislike').style.color = '#909090';
        }
        if (statuslike === false) {
            setLike(like + 1);
            setStatuslike(true);
            document.getElementById('icon-like').style.fill = '#02A6E7';
            document.getElementById('text-like').style.color = '#02A6E7';
        } else if (statuslike === true) {
            setLike(like - 1);
            setStatuslike(false);
            document.getElementById('icon-like').style.fill = '#909090';
            document.getElementById('text-like').style.color = '#909090';
        }
    }
    function clickdislike() {
        if (statuslike === true) {
            setLike(like - 1);
            setStatuslike(false);
            document.getElementById('icon-like').style.fill = '#909090';
            document.getElementById('text-like').style.color = '#909090';
        }
        if (statusdislike === false) {
            setDislike(dislike + 1);
            setStatusdislike(true);
            document.getElementById('icon-dislike').style.fill = '#02A6E7';
            document.getElementById('text-dislike').style.color = '#02A6E7';
        } else if (statusdislike === true) {
            setDislike(dislike - 1);
            setStatusdislike(false);
            document.getElementById('icon-dislike').style.fill = '#909090';
            document.getElementById('text-dislike').style.color = '#909090';
        }
    }

    return (
        <div className="detail-container">
            {/* Title */}
            <div className='title-row'>
                <label id='title'>{title} <h1>({dataset_id})</h1></label>
                <div className='title-btn'>
                    {/* <button className='like-btn' onClick={clicklike} >
                            <svg id='icon-like' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><path fill="none" d="M0 0h48v48h-48z"/><path d="M2 42h8v-24h-8v24zm44-22c0-2.21-1.79-4-4-4h-12.63l1.91-9.14c.04-.2.07-.41.07-.63 0-.83-.34-1.58-.88-2.12l-2.13-2.11-13.17 13.17c-.72.73-1.17 1.73-1.17 2.83v20c0 2.21 1.79 4 4 4h18c1.66 0 3.08-1.01 3.68-2.44l6.03-14.1c.18-.46.29-.95.29-1.46v-3.83l-.02-.02.02-.15z"/></svg>
                            <h4 id='text-like'>{like}</h4>
                        </button>
                        <button className='dislike-btn' onClick={clickdislike}>
                            <svg id='icon-dislike' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0zm0 0h24v24H0V0z"/><path d="M10.88 21.94l5.53-5.54c.37-.37.58-.88.58-1.41V5c0-1.1-.9-2-2-2H6c-.8 0-1.52.48-1.83 1.21L.91 11.82C.06 13.8 1.51 16 3.66 16h5.65l-.95 4.58c-.1.5.05 1.01.41 1.37.59.58 1.53.58 2.11-.01zM21 3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2s2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>
                            <h4 id='text-dislike'>{dislike}</h4>
                        </button> */}
                    {/* <button className='save'>Save</button> */}
                    <button className='download' onClick={ ()=>window.open(`https://store.data.storemesh.com/api/v1/datasets/${data_info.id}/download`,'_blank')} >Download</button>
                </div>
            </div>
            {/* Table */}
            <div className='detail-tabs'>

                <Tabs defaultActiveKey="1">
                    <TabPane tab={<div id='tab'>Metadata</div>} key="1" >
                        <Metadata meta_data={data_info} />
                    </TabPane>
                    <TabPane tab={<div id='tab'>Dataset</div>} key="2" >
                        <Dataset file={file} />
                    </TabPane>
                    {/* <TabPane tab={<div id='tab'>Discussion</div>} key="3" disabled>
                            <Discussion comment_data={comment} />
                        </TabPane> */}
                    {/* <TabPane tab={<div id='tab'>Data Dictionary</div>} key="2" >
                            <Table columns={columns_schema} dataSource={rows_schema} pagination={{ pageSize: 20 }} />
                        </TabPane>*/}
                    <TabPane tab={<div id='tab'>Lineage</div>} key="4">
                        <Lineage lineage_data={dataLineage} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default Info;
