import React, { useState, useEffect } from "react";
import { Tabs, List, Space, Collapse } from "antd";
import {
    MessageOutlined,
    LikeOutlined /*StarOutlined */,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../style/user.css";
import axiosInstance from "../axios-instance";
import { /*useHistory,*/ useParams } from "react-router-dom";
import Footer from "../componants/footer";
import Header from "../componants/header";

let MyDataset = () => {
    let params = useParams();
    let [data, setData] = useState([]);
    let getSearch = (search) => {
        axiosInstance.get(`http://localhost:8000/search`)
            //.get(`/datasets?q=${search}`)
            .then((response) => {
                // setData(response.data.data);
                setData(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    useEffect(() => {
        getSearch(params.search);
        console.log("Params : " + params.search);
    }, [params.search]);

    let { TabPane } = Tabs;
    const { Panel } = Collapse;
    let listData = data.map((data, index) => {
        return ({
            id: data.id,
            title: data.title,
            like: data.like,
            discussion: data.discussion,
            notes: data.notes,
            owner_org: data.data_owner,
            update_at: data.update_at,
        });
    });

    const IconText = ({ icon, text }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    return (
        <div className="list-container">
            <Header />
            <div className="list-content">
                <div className="list">
                    <Tabs style={{ minWidth: "50%" }} defaultActiveKey="2">
                        <TabPane tab={<div id="tab">Profile</div>} key="1" disabled>

                        </TabPane>
                        <TabPane tab={<div id="tab">Board</div>} key="2">
                            <Collapse 
                            defaultActiveKey={['1']} 
                            expandIconPosition='right' 
                            style={{ ["--header-border"]: "2px dashed blue" }}
                            className="collapse-panel-custom">
                                <Panel header="All" key="1" >
                                    <List
                                        itemLayout="vertical"
                                        size="large"
                                        pagination={false} 
                                        dataSource={listData}
                                        renderItem={(item) => (
                                            <List.Item
                                                id='list-board'
                                                key={item.title}
                                                // extra={[
                                                //     <IconText
                                                //         icon={LikeOutlined}
                                                //         text={item.like}
                                                //         key="list-vertical-like-o"
                                                //     />,
                                                //     <IconText
                                                //         icon={MessageOutlined}
                                                //         text={item.discussion}
                                                //         key="list-vertical-message"
                                                //     />,
                                                // ]}
                                            >
                                                <List.Item.Meta
                                                    title={
                                                        <Link to={`/detail/${item.id}`}>
                                                            {item.title}
                                                        </Link>
                                                    }
                                                    description={item.notes}
                                                />
                                                {item.owner_org}
                                            </List.Item>
                                        )}
                                    />
                                </Panel>
                            </Collapse>

                        </TabPane>
                    </Tabs>
                </div>
            </div>
            <div className='footer'>
                <Footer />
            </div>
        </div>
    );
};

export default MyDataset;
