import '../style/header.css';
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { Input, AutoComplete } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axiosInstance from '../axios-instance';

const Search = () => {

    let params = useParams();
    let history = useHistory();

    let renderItem = (id, title) => ({
        value: id,
        label: (
            <div>{title}</div>
        )
    });

    let [searchValue, setSearchValue] = useState(window.location.pathname.split('search/').pop());

    let [datas, setData] = useState([]);
    let getHome2 = (search) => {
        axiosInstance.get(`/datasets?q=${search ? search : 'ข้าว'}`)
            .then(response => {
                setData(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        getHome2();
        let detail = window.location.pathname.split('search/').pop().substring(1, 7);
        if (window.location.pathname.split('search/').pop() !== '/' && detail !== 'detail') {
            setSearchValue(params.search);
        } else {
            setSearchValue(null);
        }
    }, []);

    let options = datas.map(data => renderItem(data.id, data.title))

    let onSelect = (value, option) => {
        let path = `/search/${option.label.props.children}`;
        //console.log('hash:',value, ' title: ',option.label.props.children);
        setSearchValue(option.label.props.children);
        history.push(path);
    };

    let onKeyDown = (e) => {
        if (e.key === 'Enter') {
            let path = `/search/${e.target.value}`;
            setSearchValue(e.target.value);
            if (e.target.value !== '') {
                history.push(path);
            }
        }
    }
    return (
        <AutoComplete
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={true}
            style={{ width: '100%', borderRadius: '10px' }}
            options={options}
            onSelect={onSelect}
            onSearch={getHome2}
            onKeyDown={onKeyDown}
            value={searchValue}
        >
            <Input id='search-input' onChange={e => setSearchValue(e.target.value)} size="large" style={{ borderRadius: '14px', maxHeight: '100%', fontSize: '20px' }} placeholder="Search" prefix={<SearchOutlined />} />
        </AutoComplete>
    )
}

export default Search;