import React from 'react'
import moment from 'moment-timezone';

const Discussion = (props) => {

    const comments = [];

    try {
        Object.entries(props.comment_data).map((k, v) => 
            comments.push(k[1])
            )
            //console.log('Comments : ' + comments)
            
    } catch {}

    const Comments = () => {
        return comments.map((item) => {
            
            const timeLeft = moment(item.date_time, "YYYY-MM-DD hh:mm").tz("Asia/Bangkok").fromNow();
            // const timeLeft = moment(item.date_time, "YYYY-MM-DD hh:mm").fromNow();
            //console.log('Time Left : ' + timeLeft)
            return (
                <div className='comment-row' key={item.user+item.date_time}>
                    <div id='avatar'>{item.user[0]}</div>
                    <div className='comment-box'>
                        <div className='username-row'>
                            <h5 id='user-name'>{item.user} </h5>
                            <h5 id='comment-date'>{timeLeft}</h5>
                        </div>
                        <h5 id='user-comment'>{item.comment}</h5>
                    </div>
                </div>
            )
        })
    }

    return(
        <div className='discussion-container'>
            <Comments />
            <h3 className='new-comment'>New Comment</h3>
            <div className='add-comment-box'>
                <div id='avatar'>A</div>
                <textarea className="input-comment" id="message" placeholder="Your message" required=""></textarea>    
            </div>
            {/* <button type="submit" class="add-comment-btn">Add Comment</button> */}
        </div>
    )
}

export default Discussion;