import React, { useState, useEffect } from "react";
import { Tabs, List, Space } from "antd";
import {
  MessageOutlined,
  LikeOutlined /*StarOutlined */,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../style/list.css";
import axiosInstance from "../axios-instance";
import { /*useHistory,*/ useParams } from "react-router-dom";

import Footer from "../componants/footer";
import Header from "../componants/header";

let Lists = () => {
  let params = useParams();
  let [data, setData] = useState([]);
  let getSearch = (search) => {
    axiosInstance
      .get(`/datasets?q=${search}`)
      .then((response) => {
        // setData(response.data.data);
        setData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getSearch(params.search);
    //console.log("Params : " + params.search);
  }, [params.search]);

  let { TabPane } = Tabs;
  let listData = data.map((data, index) => {
    return ({
      id: data.id,
      title: data.title,
      // like: data.like,
      discussion: data.discussion,
      notes: data.notes,
      owner_org: data.data_owner,
      update_at: data.update_at,
    });
  });

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <div className="list">
      <Tabs style={{ minWidth: "50%" }} defaultActiveKey="1">
        <TabPane tab={<div id="tab">Data</div>} key="1">
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              pageSize: 10,
            }}
            dataSource={listData}
            renderItem={(item) => (
              <List.Item
                key={item.title}
              // extra={[
              //   <IconText
              //     icon={LikeOutlined}
              //     text={item.like}
              //     key="list-vertical-like-o"
              //   />,
              //   <IconText
              //     icon={MessageOutlined}
              //     text={item.discussion}
              //     key="list-vertical-message"
              //   />,
              // ]}
              >
                <List.Item.Meta
                  title={
                    <Link to={`/detail/${item.id}`}>
                      {item.title}
                    </Link>
                  }
                  description={item.notes}
                />
                {item.owner_org}
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Lists;
