import React from 'react'
import {getCookie, getUser, delete_cookie} from '../utils/getCookie';
import { Menu, Dropdown, Button } from 'antd';

const LoginButton = () => {
    let menu = (
        <Menu>
            <Menu.Item>
                <Button type="link" size={'large'} onClick={delete_cookie('dsm-jw')}>
                    Log out
                </Button>
            </Menu.Item>
        </Menu>
    )

    let login;
    console.log(getUser('dsm-jwt'));
    if (getCookie('dsm-jwt')!==null ) {
        login = (
            <Dropdown overlay={menu} placement="bottomCenter">
                <button className="login-btn"> {getAccountName(getUser('dsm-jwt'))} </button>
            </Dropdown>
        )
    }
    else{
        login = <a href={`${process.env.REACT_APP_OAUTH_URL || "https://oauth.data.storemesh.com"}/login?next=${process.env.REACT_APP_DISCOVERY_URL}`} ><button className="login-btn">Sign in</button></a>
    }

    function getAccountName (name){
        if(name.charAt(0) == '@'){
            let accountName = name.split('@')[1];
            //console.log(name.split('@'));
            return accountName.charAt(0);
        }else{
            return name.charAt(0);
        }
    }

    // let fake = false
    // if (getCookie('dsm-jwt')!==null ) {
    //     login = (
    //         <Dropdown overlay={menu} placement="bottomCenter">
    //             <button className="login-btn"> {getUser('dsm-jwt').split('@')[0]} </button>
    //         </Dropdown>
    //     )
    // }else if (fake) {
    //     login = (
    //         <Dropdown overlay={menu} placement="bottomCenter">
    //             <button className="account-btn"> {getAccountName("@AKIRAaaaaaaaaaaaaaaa")} </button>
    //         </Dropdown>
    //     )
    // }
    // else{
    //     login = <a href={`${process.env.REACT_APP_OAUTH_URL || "https://oauth.data.storemesh.com"}/login?next=${process.env.REACT_APP_DISCOVERY_URL}`} ><button className="login-btn">Sign in</button></a>
    // }

    return login
}

export default LoginButton;