import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Tree, Table } from 'antd';
import axiosInstance from '../axios-instance';
import { sum } from 'd3-array';

const Dataset = (props) => {
    // Data Explorer
    const [filetype, setFiletype] = useState();
    const [columns_ex, setColumns_ex] = useState(), [rows_ex, setRows_ex] = useState();
    const [columns_dic, setColumns_dic] = useState(), [rows_dic, setRows_dic] = useState();
    const { DirectoryTree } = Tree;

    const [columns_sum, setColumns_sum] = useState(
        [{
            'title': 'field_name',
            'dataIndex': 'field_name',
            'key': 'field_name'
        },
        {
            'title': "max",
            'dataIndex': "max",
            'key': "max"
        },
        {
            'title': "min",
            'dataIndex': "min",
            'key': "min"
        },
        {
            'title': "mean",
            'dataIndex': "mean",
            'key': "mean"
        }]
    ), [rows_sum, setRows_sum] = useState();

    // set icon file in data explorer
    function setLeaf(tree) {
        var tree_len = tree.length;
        for (let j = 0; j < tree_len; j++) {
            if (tree[j].children === undefined) {
                tree[j].isLeaf = true;
            } else {
                setLeaf(tree[j].children);
            }
        }
        return tree;
    }

    function getTreeData() {
        let treeData = [];
        let file_no = 1;
        try {
            treeData = Object.entries(props.file).map((value, key) => {
                let title = value[1].title;
                if (title.length >= 22) {
                    value[1].title = title.substring(0, 22) + '...';
                    //console.log('title & key:',value[1].title,value[1].key);
                }
                //console.log('key',title.length);
                return value[1]
            })
            treeData = setLeaf(treeData);
        }
        catch {

        }
        return treeData
    }

    async function getDataExample(node) {
        let dataset_id = window.location.pathname.split('detail/').pop();
        await axiosInstance.get(`/data-example/?id=${dataset_id}&file_name=${node.key}`)
            .then(response => {
                let c = response.data.count.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                });
                document.getElementById('file-name').innerHTML = node.key + ' (' + c + ' rows)';
                if (response.data.data_dictionary !== []) {
                    setColumns_ex(() => {
                        try {
                            return response.data.data_dictionary.map((data, index) => {
                                return {
                                    'title': data.field_name,
                                    'dataIndex': data.field_name,
                                    'key': data.field_name
                                }
                            })
                        } catch {
                            return [{
                                'title': '',
                                'dataIndex': '',
                                'key': ''
                            }]
                        }

                    });

                    setColumns_dic(() => {
                        try {
                            let arr = [];
                            Object.keys(response.data.data_dictionary[0]).forEach(element => {
                                arr.push({
                                    'title': element,
                                    'dataIndex': element,
                                    'key': element
                                })
                            });
                            return arr;
                        } catch {
                            return [{
                                'title': '',
                                'dataIndex': '',
                                'key': ''
                            }]
                        }

                    });

                }

                setRows_ex(() => {
                    try {
                        let row = response.data.data;
                        row.forEach(element => {
                            for (const [key, value] of Object.entries(element)) {
                                if (typeof value == 'number') {
                                    element[key] = value.toLocaleString();
                                }
                            }
                        })
                        return row;
                    } catch {
                        return response.data.data;
                    }
                });
                setRows_dic(response.data.data_dictionary);
                setRows_sum(() => {
                    try {
                        let row = response.data.describe;
                        row.forEach(element => {
                            for (const [key, value] of Object.entries(element)) {
                                if (typeof value == 'number' && element.field_name !== 'year') {
                                    //element[key] = value.toLocaleString();
                                    element[key] = value.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2
                                    });
                                }
                                //console.log(`${key}: ${value}`);
                            }
                        })
                        return row;
                    } catch {
                        return response.data.describe;
                    }
                });
            })
            .catch(e => {
                console.log(e);
            });
        // axiosInstance.get(`http://localhost:3000/datasets_example`)
        //     .then(response => {
        //         setColumns_ex(() => {
        //             //console.log("setColumns_ex");
        //             try {
        //                 return response.data.data_dictionary.map((data, index) => {
        //                     return {
        //                         'title': data.field_name,
        //                         'dataIndex': data.field_name,
        //                         'key': data.field_name
        //                     }
        //                 })
        //             } catch {
        //                 return [{
        //                     'title': '',
        //                     'dataIndex': '',
        //                     'key': ''
        //                 }]
        //             }

        //         });
        //         setRows_ex(() => {
        //             try {
        //                 let row = response.data.data;
        //                 row.forEach(element => {
        //                     //console.log('ee:',element);
        //                     for (const [key, value] of Object.entries(element)) {
        //                         if (typeof value == 'number') {
        //                             element[key] = value.toLocaleString();
        //                         }
        //                         //console.log(`${key}: ${value}`);
        //                     }
        //                 })
        //                 return row;
        //             } catch {
        //                 return response.data.data;
        //             }
        //         });
        //         setRows_sum(() => {
        //             try {
        //                 let row = response.data.describe;
        //                 row.forEach(element => {
        //                     //console.log('ee:',element);
        //                     for (const [key, value] of Object.entries(element)) {
        //                         if (typeof value == 'number') {
        //                             //element[key] = value.toLocaleString();
        //                             element[key] = value.toLocaleString(undefined, {
        //                                 minimumFractionDigits: 0,
        //                                 maximumFractionDigits: 2
        //                             });
        //                             //element[key] = value.toFixed(2).toLocaleString();
        //                         }
        //                         //console.log(`${key}: ${value}`);
        //                     }
        //                 })
        //                 return row;
        //             } catch {
        //                 return response.data.describe;
        //             }
        //         });
        //     })
        //     .catch(e => {
        //         console.log(e);
        //     });
    }

    const DataExplorer = () => {
        const onSelect = (keys, info) => {
            if (info.node.key) {
                getDataExample(info.node);
                document.getElementById('example-btn').style.display = 'block';
                document.getElementById('dic-btn').style.display = 'block';
                document.getElementById('sum-btn').style.display = 'block';
            } else {
                document.getElementById('example-btn').style.display = 'none';
                document.getElementById('dic-btn').style.display = 'none';
                document.getElementById('sum-btn').style.display = 'none';
            }
            console.log('info node', info.node);
            setFiletype(info.node.key.split('.').pop());
        };

        const onExpand = () => { };

        let treeData = getTreeData();
        return (
            <DirectoryTree
                multiple
                defaultExpandAll
                onSelect={onSelect}
                // onExpand={onExpand}
                treeData={treeData}
            />
        );
    };

    const ShowExample = () => {
        //var [advice,setAdvice] = useState('');
        if (filetype === 'csv') {
            return (
                <div id='example-table'>
                    <Table columns={columns_ex} dataSource={rows_ex} />
                </div>
            );
        } else if (filetype === 'md') {
            return (
                <div className='readme-file'>
                    <ReactMarkdown># Hello, *This file is Readme.md*!</ReactMarkdown>
                </div>
            );
        } else if (filetype === 'txt') {
            return (
                <div className='text-file'>
                    <h5>This file is text file!</h5>{/* setText(t); */}
                </div>
            );
        } else {
            return (<div id='example-table'><ReactMarkdown>No Data Available</ReactMarkdown></div>);
        }
    }

    const ShowDic = () => {
        return (
            <div id='dic-table'>
                <Table columns={columns_dic} dataSource={rows_dic} />
            </div>
        );
    }

    const ShowSum = () => {
        return (
            <div id='sum-table'>
                <Table columns={columns_sum} dataSource={rows_sum} />
            </div>
        );
    }

    function btnSelected(btn) {
        if (btn === 'example') {
            document.getElementById('example-btn').style.background = '#02A6E7';
            document.getElementById('example-btn').style.border = 'none';
            document.getElementById('example-btn').style.color = 'white';
            document.getElementById('dic-btn').style.background = 'transparent';
            document.getElementById('dic-btn').style.border = '1px solid #d9d9d9';
            document.getElementById('dic-btn').style.color = 'black';
            document.getElementById('sum-btn').style.background = 'transparent';
            document.getElementById('sum-btn').style.border = '1px solid #d9d9d9';
            document.getElementById('sum-btn').style.color = 'black';
            document.getElementById('example-table').style.display = 'block';
            document.getElementById('dic-table').style.display = 'none';
            document.getElementById('sum-table').style.display = 'none';
        } else if (btn === 'dic') {
            document.getElementById('example-btn').style.background = 'transparent';
            document.getElementById('example-btn').style.border = '1px solid #d9d9d9';
            document.getElementById('example-btn').style.color = 'black';
            document.getElementById('dic-btn').style.background = '#02A6E7';
            document.getElementById('dic-btn').style.border = 'none';
            document.getElementById('dic-btn').style.color = 'white';
            document.getElementById('sum-btn').style.background = 'transparent';
            document.getElementById('sum-btn').style.border = '1px solid #d9d9d9';
            document.getElementById('sum-btn').style.color = 'black';
            document.getElementById('example-table').style.display = 'none';
            document.getElementById('dic-table').style.display = 'block';
            document.getElementById('sum-table').style.display = 'none';
        } else if (btn === 'sum') {
            document.getElementById('example-btn').style.background = 'transparent';
            document.getElementById('example-btn').style.border = '1px solid #d9d9d9';
            document.getElementById('example-btn').style.color = 'black';
            document.getElementById('dic-btn').style.background = 'transparent';
            document.getElementById('dic-btn').style.border = '1px solid #d9d9d9';
            document.getElementById('dic-btn').style.color = 'black';
            document.getElementById('sum-btn').style.background = '#02A6E7';
            document.getElementById('sum-btn').style.border = 'none';
            document.getElementById('sum-btn').style.color = 'white';
            document.getElementById('example-table').style.display = 'none';
            document.getElementById('dic-table').style.display = 'none';
            document.getElementById('sum-table').style.display = 'block';
        }
    }

    return (
        <div className='dataset-container'>
            <div className='data-explorer'>
                <p>Data Explorer</p>
                <DataExplorer />
            </div>
            <div className='show-data-explorer'>
                <div id='table-btn-row'>
                    <button className='example-btn' id='example-btn' onClick={() => { btnSelected('example') }}>Example</button>
                    <button className='dic-btn' id='dic-btn' onClick={() => { btnSelected('dic') }}>Data Dictionary</button>
                    <button className='sum-btn' id='sum-btn' onClick={() => { btnSelected('sum') }}>Summary</button>
                </div>
                <p id='file-name'>No files have been selected.</p>
                <ShowExample />
                <ShowDic />
                <ShowSum />
                {/* <p>{text}</p> */}
            </div>
        </div>
    )
}

export default Dataset;